import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant';
import { Lazyload } from 'vant';

import 'vant/lib/index.css';
import './index.less'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisV, faCopy,faMap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueClipboard from 'vue-clipboard2'

library.add(faEllipsisV)
library.add(faCopy)
library.add(faMap)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(VueClipboard)

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
