import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        build: '0',
        token: localStorage.getItem('token') || '',
        lastTab: ''
    },
    getters: {
        showBuild: state => {
            return state.build;
        },
        showToken: state => {
            return state.token;
        },
        showLastTab: state => {
            return state.lastTab;
        }
    },
    mutations: {
        updateToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        updateLastTab(state, tabName) {
            state.lastTab = tabName;
        },
        updateBuild(state, build) {
            state.build= build;
        }
    },
    actions: {},
    modules: {}
})
