<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            refreshing: false
        };
    },
    components: {},
    mounted() {
        this.$store.commit('updateBuild', document.documentElement.dataset.buildTimestamp);

        //传递事件测试，在其他页面created() {this.$root.$emit('body-background-color', '#F2F2F2')};
        // this.$root.$on('body-background-color', function(color) {
        //     document.body.style.background = color;
        //     //handle the background color change here
        // });
    },
    methods: {},
};
</script>

<style>

/*主要颜色：var(--color) */

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #333333;
    background: #F6F6FC;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

p {
    margin: 0;
}

a {
    color: #68B1FA;
}


.clickable-li {
    cursor: pointer;
}

.clickable-li:hover {
    color: #00A0EB;
}

</style>
